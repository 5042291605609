import React, { memo, useContext } from 'react';
import styled from 'styled-components';

import { PageContext } from './PageProvider';

import TYPO from '../styles/typography';
import MailchimpForm, { Input } from './MailchimpForm';

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`;

const Heading = styled.h1`
  ${TYPO.h1}
`;

const Subheading = styled.h1`
  ${TYPO.subheading}
`;

const HeadingSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
  width: 100%;
`;

type SubscribeSectionProps = {
  heading?: string;
  subHeading?: string;
  cta?: string;
};

function SubscribeSection({ heading, subHeading, cta }: SubscribeSectionProps) {
  const { generic } = useContext(PageContext);

  return (
    <Container>
      <HeadingSection>
        <Heading>
          {heading || generic.blogPostSettings?.newsletterHeading}
        </Heading>
        <Subheading>
          {subHeading || generic.blogPostSettings?.newsletterSubheading}
        </Subheading>
      </HeadingSection>
      <Content>
        <MailchimpForm label={cta ?? 'Subscribe'} icon="ArrowRight">
          <Input type="email" name="EMAIL" placeholder="Your email" required />
        </MailchimpForm>
      </Content>
    </Container>
  );
}

export default memo(SubscribeSection);
