import styled from 'styled-components';

import COLORS from './colors';
import TYPO from './typography';

export const Tag = styled.div`
  display: flex;
  padding: 1px 3px;
  border-radius: 3px;
  background: ${COLORS.success.light.css};
  color: ${COLORS.success.regular.css};

  &[data-variant='warn'] {
    background: ${COLORS.warn.light.css};
    color: ${COLORS.warn.dark.css};
  }

  &[data-variant='grey'],
  &[data-variant='gray'] {
    background: ${COLORS.shades.s100.css};
    color: ${COLORS.shades.s300.css};
  }

  ${TYPO.preheading};
`;
