import React, { ForwardedRef, forwardRef } from 'react';
import styled from 'styled-components';

import COLORS from '../styles/colors';
import TYPO from '../styles/typography';
import { usePageContext } from './PageProvider';
import Button from '../styles/button';
import Icon, { IconName } from './Icon';

export const Input = styled.input`
  ${TYPO.p1}
  height: 100%;
  border: none;
  outline: none;
  flex: 1;
  width: 100%;
  border-width: 1px;
  border-style: solid;
  border-color: ${COLORS.shades.s300.css};
  border-radius: 3px;
  padding: 9px 18px 9px 12px;

  &::placeholder {
    color: ${COLORS.shades.s250.css};
  }

  &[data-error='true'] {
    border-color: ${COLORS.error.regular.css};
  }
`;

export const HiddenInput = styled.input`
  position: absolute;
  left: -5000px;
  display: none;
`;

const Form = styled.form`
  display: flex;
  gap: 12px;
  padding: 12px 0 18px;
  max-width: 480px;
  width: 100%;
  position: relative;
`;

type MailchimpFormProps = {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  label?: string;
  icon?: IconName;
};

function MailchimpForm(
  {
    children,
    label = 'Get Notified',
    icon = 'Bell',
    ...props
  }: MailchimpFormProps,
  ref: ForwardedRef<HTMLFormElement>
) {
  const { generic } = usePageContext();

  return (
    <Form
      action={generic.mailchimpHandbookForm}
      method="post"
      target="_blank"
      ref={ref}
      {...props}
    >
      {children}
      <Button type="submit" $variant="default">
        <Icon name={icon} width={12} height={12} />
        {label}
      </Button>
    </Form>
  );
}

export default forwardRef<HTMLFormElement, MailchimpFormProps>(MailchimpForm);
